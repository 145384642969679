import React, { useEffect, useState } from 'react';
import './create-return-view.scss';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import { SearchBar } from '@/components/sections';
import CreateReturnComponent from './components/create-return-component';
import ScrollUtil from '@/components/utils/scroll-to-top-util/scroll-to-top-util';
import ErrorPage from '@/components/sections/profile-error/error-page';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { useLocation } from 'react-router-dom';
import { findPathByAlias } from '@/routes/routesList';
import { useOrderDetailsContent } from '../order-details-view/hooks/use-order-details-content';

const CreateReturnView = () => {
    const [showErrorPage, handleShowErrorPage] = useState(false);
    const appConfig = new AppConfigurationService();
    const location = useLocation();
    const createReturnContent = useOrderDetailsContent();

    useEffect(() => {
        new ScrollUtil().scrollPageToTop();
        handleShowErrorPage(false);
    }, []);

    return (
        <>
            {showErrorPage && <ErrorPage />}
            {!showErrorPage && !createReturnContent && (
                <ActivityIndicator className={'full-height'} />
            )}
            {!showErrorPage && createReturnContent ? (
                <div
                    className="create-return-container"
                    data-test-id="create-return-view"
                >
                    <Breadcrumbs
                        currentPage={
                            createReturnContent.createReturnBreadcrumbTitle
                        }
                        previousPage={
                            createReturnContent.createReturnBreadcrumbPreviousTitle
                        }
                        secondPreviousPage={
                            createReturnContent.createReturnBreadcrumbSecondPreviousTitle
                        }
                        previousLink={
                            location.state?.orderDetails
                                ? findPathByAlias(
                                      'OrderDetailsViewWithoutOrderNumber',
                                      location.state?.orderDetails
                                  )
                                : findPathByAlias('OrderHistoryView')
                        }
                        secondPreviousLink={findPathByAlias('OrderHistoryView')}
                        crumbSeparator={appConfig.brand === 'ford' ? '/' : '>'}
                    ></Breadcrumbs>
                    <CreateReturnComponent />
                    <hr className="hr-line" aria-hidden={true} />
                    <SearchBar />
                </div>
            ) : null}
        </>
    );
};

export default CreateReturnView;
