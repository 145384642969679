import React, { useState } from 'react';
import { useGuestReturnLoginContent } from '@views/guest-login-views/guest-return-view/hooks/use-guest-return-login-content';
import GuestLoginComponent from '@views/guest-login-views/components/guest-login-component';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import { OmsOrderDetailsResponse } from '@services/oms-service/oms-service';

const GuestReturnView = () => {
    const guestReturnContent = useGuestReturnLoginContent();
    const navigate = useNavigate();

    const [emailAddress, setEmailAddress] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<string>('');
    const [errorNotificationMessage, setErrorNotificationMessage] =
        useState<string>(null);

    const handleOrderDetailsSuccess = (
        orderDetails: OmsOrderDetailsResponse
    ) => {
        if (
            orderDetails?.orderLine?.some(
                (order) => order.isStorefrontReturnable === 'Y'
            )
        ) {
            navigate(findPathByAlias('CreateReturnView'), {
                state: {
                    orderDetails,
                },
            });
        } else {
            setErrorNotificationMessage(
                guestReturnContent.noReturnableItemsErrorMessage
            );
        }
    };

    return (
        <>
            <GuestLoginComponent
                guestLoginContent={guestReturnContent}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                orderNumber={orderNumber}
                setOrderNumber={setOrderNumber}
                errorNotificationMessage={errorNotificationMessage}
                setErrorNotificationMessage={setErrorNotificationMessage}
                handleOrderDetailsSuccess={handleOrderDetailsSuccess}
            />
        </>
    );
};

export default GuestReturnView;
