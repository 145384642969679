import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import Link from '../link/link';

import './secondary-button.scss';

export type ButtonType =
    | ''
    | 'dark-left'
    | 'white-left'
    | 'chevron-left'
    | 'blue-filled'
    | 'blue'
    | 'chevron-right'
    | 'ford-add-address'
    | 'lincoln-add-address';

interface Props {
    onClick?: () => void;
    link?: string;
    linkTarget?: string;
    children?: ReactNode;
    className?: string;
    spanClassName?: string;
    direction?: 'up' | 'down' | 'left' | 'right';
    noChevron?: boolean;
    buttonType?: ButtonType;
    internal?: boolean;
    id?: string;
    dataTestId?: string;
    dataDark?: boolean;
    isFromCardMenu?: boolean;
    isLincolnPermanentUnderline?: boolean;
}

const SecondaryButton = (props: Props & React.HTMLProps<HTMLAnchorElement>) => {
    const btnClass = `${props.className}`;

    return (
        <div className="secondary-button">
            {!props.internal && props.link && (
                <Link
                    onClick={props.onClick}
                    className={btnClass}
                    href={props.link}
                    target={props.linkTarget}
                    aria-label={props['aria-label']}
                    id={props?.id}
                    data-testid={props.dataTestId}
                    data-dark={props.dataDark}
                >
                    <span
                        className={`secondary-button-text ${props.spanClassName}`}
                    >
                        {props.children}
                    </span>
                    {props.isFromCardMenu && (
                        <div className="underline-overlay__card-menu-link"></div>
                    )}
                    {props.isLincolnPermanentUnderline && (
                        <div className="lincoln-underline-button-overlay"></div>
                    )}
                </Link>
            )}
            {!props.internal && !props.link && (
                <button
                    className={`${btnClass} button-style`}
                    onClick={() => {
                        props.onClick();
                    }}
                    data-dark={props.dataDark}
                    aria-label={props['aria-label']}
                    id={props?.id}
                    data-testid={props.dataTestId}
                >
                    <span className="secondary-button-text">
                        {props.children}
                    </span>
                </button>
            )}
            {props.internal && props.link && (
                <NavLink
                    className={btnClass}
                    to={props.link}
                    aria-label={props['aria-label']}
                    id={props?.id}
                    data-testid={props.dataTestId}
                    onClick={props.onClick}
                >
                    <span className="secondary-button-text">
                        {props.children}
                    </span>
                </NavLink>
            )}
            {props.internal && !props.link && (
                <button
                    className={`${btnClass} button-style`}
                    onClick={() => {
                        props.onClick();
                    }}
                    aria-label={props['aria-label']}
                    id={props?.id}
                    data-testid={props.dataTestId}
                >
                    <span className="secondary-button-text">
                        {props.children}
                    </span>
                </button>
            )}
        </div>
    );
};

export default SecondaryButton;
