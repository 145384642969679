import {
    OrderHistoryOrder,
    OrderLine,
} from '@services/oms-service/oms-service';
import { AccessoriesCarouselItem } from '@sections/active-orders-carousel/active-orders-carousel';

export interface OrderStatusOption {
    name: string;
    options: string[];
    isActive?: boolean;
}

export interface StatusMapKeys {
    shipToHome: OrderStatusOption[];
    pickUpOrder: OrderStatusOption[];
    installLines: OrderStatusOption[];
}

export const NVC_ORDER_STATUS_MAP: StatusMapKeys = {
    shipToHome: [
        { name: 'shipped', options: ['completed'] },
        {
            name: 'preparingToShip',
            options: ['included in shipment'],
            isActive: true,
        },
        { name: 'returnCreated', options: ['return created'] },
        {
            name: 'processing',
            options: ['scheduled', 'released', 'received'],
            isActive: true,
        },
        { name: 'backordered', options: ['backordered'], isActive: true },
        { name: 'placed', options: ['created'], isActive: true },
        { name: 'returned', options: ['return completed'] },
        { name: 'cancelled', options: ['cancelled'] },
    ],
    pickUpOrder: [
        { name: 'readyForPickup', options: ['included in shipment'] },
        { name: 'pickedUp', options: ['picked up'] },
        { name: 'returnCreated', options: ['return created'] },
        {
            name: 'inTransitToDealer',
            options: ['shipment in transit'],
            isActive: true,
        },
        {
            name: 'processing',
            options: [
                'scheduled',
                'released',
                'received',
                'warehouse processing',
                'awaiting shipment',
            ],
            isActive: true,
        },
        { name: 'placed', options: ['created'], isActive: true },
        { name: 'returned', options: ['return completed'] },
        { name: 'cancelled', options: ['cancelled'] },
    ],
    installLines: [
        { name: 'orderDelivered', options: ['order delivered'] },
        { name: 'created', options: ['created'] },
    ],
};

export const NVC_GREEN_STATUSES: string[] = [
    'preparing to ship',
    'shipped',
    'returned',
    'in transit to dealer',
    'ready for pickup',
    'picked up',
    'order delivered',
    'completed',
    'included in shipment',
    'return completed',
    'shipment in transit',
    'preparándose para el envío',
    'enviado',
    'devuelto',
    'en tránsito al concesionario',
    'listo para recoger',
    'recogido',
    'pedido entregado',
    'completado',
    'incluido en el envío',
    'devolución completada',
    'envío en tránsito',
];

export const NVC_RED_STATUSES: string[] = [
    'not eligible for return',
    'backordered',
    'cancelled',
    'pedido pendiente',
    'cancelado',
    'no elegible para devolución',
];

export const generateActiveOrderItems = (
    activeOrders: OrderHistoryOrder[]
): AccessoriesCarouselItem[] => {
    let workingItems: AccessoriesCarouselItem[] = [];

    activeOrders.forEach((order: OrderHistoryOrder) => {
        order.OrderLines.OrderLine.forEach((item: OrderLine) => {
            workingItems = [...workingItems, { order, item }];
        });
    });

    return workingItems;
};

export const transformDeliveryMethod = (deliveryMethod: string): string => {
    let itemDeliveryMethod: string = deliveryMethod;

    if (itemDeliveryMethod === 'SHP') {
        itemDeliveryMethod = 'shipToHome';
    } else if (itemDeliveryMethod === 'PICK') {
        itemDeliveryMethod = 'pickUpOrder';
    } else {
        itemDeliveryMethod = 'installLines';
    }

    return itemDeliveryMethod;
};

export const filterForActiveItems = (
    items: AccessoriesCarouselItem[]
): AccessoriesCarouselItem[] => {
    let filtered: AccessoriesCarouselItem[] = [];

    items.forEach((item: AccessoriesCarouselItem) => {
        let itemDeliveryMethod = item.item.deliveryMethod;

        itemDeliveryMethod = transformDeliveryMethod(itemDeliveryMethod);

        NVC_ORDER_STATUS_MAP[itemDeliveryMethod].forEach(
            (statusOption: OrderStatusOption) => {
                if (
                    statusOption.options.includes(
                        item.item.Status.toLowerCase()
                    ) &&
                    statusOption.isActive
                ) {
                    filtered = [...filtered, item];
                }
            }
        );
    });

    return filtered;
};
