import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { BetaWrapper } from '@utils/beta-wrapper/beta-wrapper';
import { useAppInformationContent } from './hook/use-breadcrumb-content';
import './breadcrumbs.scss';
import { SYNDICATED_HEADER_COUNTRIES } from '@/constants';
import { findPathByAlias } from '@/routes/routesList';
import { useWindowSize } from '@/hooks/use-window-size';

interface Props {
    currentPage: string;
    crumbSeparator: '/' | '>';
    hideBaseBreadcrumb?: boolean;
    previousPage?: string;
    previousLink?: string;
    secondPreviousPage?: string;
    secondPreviousLink?: string;
    thirdPreviousPage?: string;
    thirdPreviousLink?: string;
    fourthPreviousPage?: string;
    fourthPreviousLink?: string;
}

export const Breadcrumbs = (props: Props) => {
    const appInformationContent = useAppInformationContent();
    const isLandingPage = props.currentPage === 'Landing';
    const { currentRegionCode } = new AppConfigurationService();
    const size = useWindowSize();
    const [isMobile, setIsMobile] = useState<boolean>(size.width < 768);
    const [showFullBreadcrumb, setShowFullBreadcrumb] = useState<boolean>(true);
    const isSyndicatedHeader =
        SYNDICATED_HEADER_COUNTRIES.includes(currentRegionCode);

    const HomepageBreadcrumb = () => {
        if (appInformationContent) {
            return (
                <BetaWrapper
                    text={appInformationContent.breadcrumbStartingValue}
                />
            );
        } else {
            return <></>;
        }
    };

    useEffect(() => {
        if (size.width < 768 && !isMobile) {
            setIsMobile(true);
            if (!props.hideBaseBreadcrumb) {
                setShowFullBreadcrumb(false);
            }
        } else if (size.width >= 768 && isMobile) {
            setIsMobile(false);
            setShowFullBreadcrumb(true);
        }
    }, [size.width]);

    const DynamicBreadcrumbs = () => {
        return appInformationContent && size.width ? (
            <>
                {!props.hideBaseBreadcrumb ? (
                    <>
                        <Link
                            to={findPathByAlias('GlobalAccountHomePage')}
                            className="breadcrumb-link"
                        >
                            <BetaWrapper
                                text={
                                    appInformationContent.breadcrumbStartingValue
                                }
                            />
                        </Link>
                        <span className="back-slash">
                            {props.crumbSeparator}
                        </span>
                    </>
                ) : null}
                {props.previousPage && isMobile && !showFullBreadcrumb && (
                    <>
                        <Link
                            to={''}
                            className="breadcrumb-link"
                            onClick={() => {
                                setShowFullBreadcrumb(true);
                            }}
                        >
                            <BetaWrapper text={'...'} />
                        </Link>
                        <span className="back-slash">
                            {props.crumbSeparator}
                        </span>
                    </>
                )}
                {showFullBreadcrumb && (
                    <>
                        {props.fourthPreviousPage && (
                            <>
                                <Link
                                    to={props.fourthPreviousLink}
                                    className="breadcrumb-link"
                                >
                                    <BetaWrapper
                                        text={props.fourthPreviousPage}
                                    />
                                </Link>
                                <span className="back-slash">
                                    {props.crumbSeparator}
                                </span>
                            </>
                        )}
                        {props.thirdPreviousPage && (
                            <>
                                <Link
                                    to={props.thirdPreviousLink}
                                    className="breadcrumb-link"
                                >
                                    <BetaWrapper
                                        text={props.thirdPreviousPage}
                                    />
                                </Link>
                                <span className="back-slash">
                                    {props.crumbSeparator}
                                </span>
                            </>
                        )}
                        {props.secondPreviousPage && (
                            <>
                                <Link
                                    to={props.secondPreviousLink}
                                    className="breadcrumb-link"
                                >
                                    <BetaWrapper
                                        text={props.secondPreviousPage}
                                    />
                                </Link>
                                <span className="back-slash">
                                    {props.crumbSeparator}
                                </span>
                            </>
                        )}
                        {props.previousPage && (
                            <>
                                <Link
                                    to={props.previousLink}
                                    className="breadcrumb-link"
                                >
                                    <BetaWrapper text={props.previousPage} />
                                </Link>
                                <span className="back-slash">
                                    {props.crumbSeparator}
                                </span>
                            </>
                        )}
                    </>
                )}
                <span className="current-page">{props.currentPage}</span>
            </>
        ) : (
            <></>
        );
    };

    return (
        <div
            className={`breadcrumbs ${
                isSyndicatedHeader ? 'syndicated-header' : ''
            }`}
            data-testid="breadcrumbs-component"
        >
            <div className="breadcrumbs-content">
                {isLandingPage ? (
                    <HomepageBreadcrumb />
                ) : (
                    <DynamicBreadcrumbs />
                )}
            </div>
        </div>
    );
};
